/**  =====================
      Authentication css start
==========================  **/
.auth-wrapper {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    min-width: 100%;
    min-height: 100vh;
    background: $primary-color;

    i {
        font-size: 20px;
        color: #AFB5BD;
    }

    h4 {
        font-size: 21px;
        color: #504F4A;
        margin-bottom: 20px;
        max-width: 80%;
    }

    img.logo {
        width: 17vh;
        margin-bottom: 5vh;
    }

    @media (min-width: 1200px) {
        background: url('./../../../images/auth/bg.jpg');
        background-size: 120%;
        .container {
            max-width: 1140px;
        }
    }
    @media (-ms-high-contrast: none), (-ms-high-contrast: active) {
        display: block;
        margin: 0 auto;

        > .row {
            min-height: 100vh;
        }
    }

    .separator {
        position: relative;
        margin: 8px 0;

        span {
            background: #fff;
            position: relative;
            padding: 0 10px;
            z-index: 5;
            font-size: 20px;
        }

        &:after {
            content: "";
            position: absolute;
            top: 15px;
            left: 0;
            width: 100%;
            height: 1px;
            background: $theme-border;
            z-index: 1;
        }
    }

    a,
    p > a {
        color: $primary-color;
        font-weight: 600;
        text-decoration: underline;
    }

    .input-group {
        background: transparent;
    }

    .card {
        margin-bottom: 0;
        padding: 8px;

        .card-body {
            padding: 20px 25px 20px 40px;
        }
        @media (-ms-high-contrast: none), (-ms-high-contrast: active) {
            display: block;
        }
    }

    > div {
        z-index: 5;
    }

    .auth-content {
        position: relative;
        padding: 15px;
        z-index: 5;

        &:not(.container) {
            width: 550px;

            .card-body {
                padding: 50px 65px 80px 65px;
            }
        }
        @media (-ms-high-contrast: none), (-ms-high-contrast: active) {
            margin: 0 auto;
        }
    }

    .auth-side-img {
        padding-right: 400px;
        position: absolute;
        width: 100%;
        top: 0;
        left: 0;
        justify-content: center;
        @media only screen and (max-width: 991px) {
            display: none;
        }
    }

    .auth-side-form {
        position: relative;
        min-height: 100%;
        background: #fff;
        display: inline-flex;
        align-items: center;
        left: auto;
        float: right;

        > * {
            position: relative;
            z-index: 5;
        }
        @media only screen and (max-width: 991px) {
            width: 100%;
            justify-content: center;

            .auth-content:not(.container) {
                max-width: 350px;
            }
        }
    }

    @media only screen and (max-width: 767px) {
        .card {
            .card-body {
                padding: 30px 15px;
            }
        }
    }
}
/**====== Authentication css end ======**/


